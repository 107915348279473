import {Grid, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import privateSpaceImg from "../images/private-space.jpg";
import privatePicto from "../images/privatisation.png";
import React, {FunctionComponent} from "react";

const PrivateSpace:FunctionComponent<{isSmallDevice: boolean}> = ({isSmallDevice}) => {
    const theme = useTheme();
    return (
        <>
            <Grid container sx={{background: theme.palette.primary.main}} justifyContent={'center'} py={4}>
                <Typography variant={'h4'} color={'common.white'} id={"private"}>
                    Privatisation & Evènements
                </Typography>
            </Grid>
            <Grid container sx={{background: theme.palette.primary.main}} p={4} justifyContent={'center'}>
                <Grid container item xs={12} md={8}>
                    <Grid container item xs={12} md={6} justifyContent={'center'}>
                        <img src={String(privateSpaceImg)} alt={'private-space'} style={{width: '100%', height: 'auto'}}/>
                    </Grid>
                    <Grid container item xs={12} md={6} alignItems={'center'} p={2}>
                        <Typography variant={'subtitle1'} color={'common.white'}>
                            Clusius peut accueillir votre évènement. Deux espaces possibles : Le rez-de-chaussée et/ou le premier étage !
                            Que vous ayez besoin d’un environnement professionnel (groupes de travail, team building, réunions, conférences...) Le premier étage se module selon vos besoins!
                            Si en revanche vous cherchez un moment de fête ou de détente, tout est possible.
                            Chez Clusius vous pouvez privatiser l’ensemble des espaces ou simplement le premier étage.
                            Nous aurons à coeur d’aménager nos espaces selon vos besoins pour que votre évènement soit mémorable.
                            Une petite scène permet également d’organiser des conférences, de profiter d’un DJ pour votre soirée, ou de prestations scèniques.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{background: theme.palette.primary.main, overflowX: 'hidden'}} p={4} justifyContent={'center'}>
                <Grid item xs={12} md={8} lg={6}>
                    <img src={String(privatePicto)} alt={'private-picto'} style={{width: '100%', height: 'auto', transform: isSmallDevice ? 'scale(1.3)' : 'scale(1)'}}/>
                </Grid>
            </Grid>
        </>
    );
}

export default PrivateSpace;
