import {Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import ImgSlider from "./Slider";
import React from "react";

const Clusetterie = () =>
    <>
        <Grid container p={4} justifyContent={'center'}>
            <Typography variant={'h4'} id={"clusetterie"}>
                La Clusetterie
            </Typography>
        </Grid>
        <Grid container p={4}>
            <Typography variant={'subtitle1'} align={'center'}>
                &#8220; Clusius c'est le nouveau restaurant Street Food à Lyon. Chez Clusius on mange des Clusettes
                ! Les Clusettes, ce sont ces délicieuses gaufres en forme de barquette, garnies de nos recettes
                salées et sucrées. Les Clusettes sont une invitation au voyage ... il y en a pour tous les goûts !
                Sur place ou à emporter, tu peux venir déguster une bonne Clusette avec un cocktail ou alors la
                consommer dehors, chez toi, au bureau, au musée, à la bibliothèque, dans le métro, chez ta mamie, à
                l'école, à la salle... enfin où tu veux quoi ! &#8221;
            </Typography>
        </Grid>
        <Container sx={{overflowX: 'hidden'}}>
            <ImgSlider/>
        </Container>
    </>

export default Clusetterie;
