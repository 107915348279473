import React, {useState} from 'react';
import "./App.css";
import {Container, CssBaseline, useMediaQuery, useTheme} from "@mui/material";
import Navbar from "./components/Navbar";
import Overview from "./components/Overview";
import Clusetterie from "./components/Clusetterie";
import PrivateSpace from "./components/PrivateSpace";
import SwipeableTDrawer from "./components/SwipeableDrawer";
import Charles from "./components/Charles";
import BookTable from "./components/BookTable";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import MentionLegale from "./components/MentionLegale";

const App = () => {
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, toggleDrawer] = useState<boolean>(false);
    const [mentionOpen, toggleMention] = useState<boolean>(false);

    return (
        <>
            <Container maxWidth={false} sx={{minHeight: '100vh'}} disableGutters>
                <CssBaseline/>
                <Navbar open={drawerOpen} toggleDrawer={(open) => toggleDrawer(open)}/>
                <MentionLegale open={mentionOpen} handleClose={() => toggleMention(false)} />
                <SwipeableTDrawer open={drawerOpen} toggleDrawer={(open) => toggleDrawer(open)}/>
                <Overview isSmallDevice={isSmallDevice} />
                <Clusetterie />
                <PrivateSpace isSmallDevice={isSmallDevice} />
                <Charles />
                <BookTable />
                <Contact isSmallDevice={isSmallDevice} />
                <Footer handleOpen={() => toggleMention(true)} />
            </Container>
        </>
    );
}

export default App;
