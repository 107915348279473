import {Grid, useMediaQuery, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import CharlesImg from "../images/charles-clusius.png";

const Charles = () => {
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Grid container sx={{background: theme.palette.primary.main}} justifyContent={'center'} py={6} id={"charles"}>
                <Typography variant={'h4'} color={'common.white'}>
                    Eh mais c’est qui Clusius ?!
                </Typography>
            </Grid>
            <Grid container sx={{background: theme.palette.primary.main}} p={4} justifyContent={'center'}>
                <Grid container item justifyContent={'center'} xs={12} md={4}>
                    <img src={CharlesImg} alt={'Charles Clusius'} style={{objectFit: 'contain', maxHeight: isSmallDevice ? 150 : 250}} />
                </Grid>
                <Grid container alignItems={'center'} item xs={12} md={8}>
                    <Typography variant={'subtitle1'} color={'common.white'} py={2}>
                        Charles de l’Écluse, ou Clusius pour les potos, était un scientifique, botaniste, naturaliste, médecin et premier mycologue au monde. Sacré CV !
                        Il vécu au XVIème siècle et est à l’origine de tellement de choses concernant la botanique, l’agriculture et la gastronomie, qu’on a pas la place ici pour te faire toute la liste, mais tu iras voir sur Wikipédia.
                    </Typography>
                    <Typography variant={'subtitle1'} color={'common.white'} py={2}>
                        Son kiffe à Clusius, c’était de traîner avec les navigateurs.<br/>
                        A l’époque tout le monde faisait des allers-retours pour l’exploration des Amériques et des Indes, du coup il en profitait pour ramener des nouvelles espèces de plantes, de fruits et de légumes pour voir comment on pouvait les cultiver chez nous afin de l’intégrer dans l’alimentation des européens.
                        Si on est capable, aujourd’hui de faire des bonnes Clusettes, avec des recettes du monde entier, c’est sûrement grâce à lui !
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default Charles;
