import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

const BookTable = () => {
    return (
        <>
            <Grid container p={4} justifyContent={'center'}>
                <Typography variant={'h4'} id={"réserver"}>
                    Réservez
                </Typography>
            </Grid>
            <Grid container justifyContent={'center'} sx={{height: 300}}>
                <iframe src="https://widget.thefork.com/6906f5c9-92c4-4d72-b9cb-0f1092f348b2"
                        style={{width: '100%', border: 0, overflow: 'hidden'}}></iframe>
            </Grid>
        </>
    )
}

export default BookTable;
