import * as React from 'react';
import {FunctionComponent} from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import {Grid, IconButton, useMediaQuery, useTheme} from "@mui/material";
import {Menu} from "@mui/icons-material";

const ResponsiveAppBar: FunctionComponent<{ open: boolean, toggleDrawer: (open: boolean) => void }> = (
    {
        toggleDrawer,
        open
    }) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AppBar position="fixed" sx={{
            backgroundColor: theme.palette.primary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            height: 60,
            justifyContent: 'center'
        }}>
            <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
                {
                    !isMd && (
                        <Grid item xs={2}>
                            <IconButton color={'inherit'} onClick={() => toggleDrawer(!open)}>
                                <Menu/>
                            </IconButton>
                        </Grid>
                    )
                }
                <Grid container item xs={isMd ? 10 : 8} justifyContent={isMd ? 'start' : 'center'}>
                    <Grid item px={1}>
                        <Typography variant={isMd ? 'h5' : 'h4'} color={'secondary.main'}>
                            Street food
                        </Typography>
                    </Grid>
                    <Grid item xs={isMd ? 'auto' : 7} md={'auto'} px={1}>
                        <Typography variant={isMd ? 'h5' : 'h4'} align={'center'} color={'#FFF'}>
                            Bar à gaufres
                        </Typography>
                    </Grid>
                    <Grid item xs={isMd ? 'auto' : 5} md={'auto'} px={1}>
                        <Typography variant={isMd ? 'h5' : 'h4'} align={'left'} color={'#FFF'}>
                            Cocktails
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={2} justifyContent={'end'}>
                    {
                        isMd && (
                            <IconButton color={'inherit'} onClick={() => toggleDrawer(!open)}>
                                <Menu/>
                            </IconButton>
                        )
                    }
                </Grid>
            </Grid>
        </AppBar>
    );
}
export default ResponsiveAppBar;
