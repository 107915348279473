import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gallery1 from "../images/gallery/gallery-1.jpg";
import gallery2 from "../images/gallery/gallery-2.jpg";
import gallery3 from "../images/gallery/gallery-3.jpg";
import gallery4 from "../images/gallery/gallery-4.jpg";
import gallery5 from "../images/gallery/gallery-5.jpg";
import gallery6 from "../images/gallery/gallery-6.jpg";
import gallery7 from "../images/gallery/gallery-7.jpg";
import gallery8 from "../images/gallery/gallery-8.jpg";
import gallery9 from "../images/gallery/gallery-9.jpg";
import gallery10 from "../images/gallery/gallery-10.jpg";
import gallery11 from "../images/gallery/gallery-11.jpg";
import gallery12 from "../images/gallery/gallery-12.jpg";
import gallery13 from "../images/gallery/gallery-13.jpg";
import gallery14 from "../images/gallery/gallery-14.jpg";
import gallery15 from "../images/gallery/gallery-15.jpg";
import gallery16 from "../images/gallery/gallery-16.jpg";
import gallery17 from "../images/gallery/gallery-17.jpg";
import gallery18 from "../images/gallery/gallery-18.jpg";

import React from "react";
import Slider, {Settings} from "react-slick";
import {ArrowLeft, ArrowRight} from "@mui/icons-material";


const itemData: Array<object|string> = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10,
    gallery11,
    gallery12,
    gallery13,
    gallery14,
    gallery15,
    gallery16,
    gallery17,
    gallery18,
];

const ImgSlider = () => {
    const settings: Settings = {
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true,
        adaptiveHeight: true,
        // nextArrow: <ArrowRight fontSize={'large'} color={'secondary'} />,
        // prevArrow: <ArrowLeft fontSize={'large'} color={'secondary'}/>,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
        cssEase: "linear",
    };

    return (
        <div className="slider-container">
        <Slider {...settings}>
            {
                itemData.map((el, key) => <div className={'slider'} key={key}><img src={String(el)} alt={`p${key}`} /></div>)
            }
        </Slider>
        </div>
    );
}

export default ImgSlider;
