import * as React from "react";
import {FunctionComponent} from "react";
import {List, ListItem, ListItemButton, ListItemText, SwipeableDrawer, useMediaQuery, useTheme} from "@mui/material";

const pages = ["La clusetterie", "Privatisation & Evènements", 'Charles de clusius', "Réservez", 'Nous Contacter'];
const anchors = ["clusetterie", "private", 'charles', "réserver", 'contact'];

const SwipeableTDrawer: FunctionComponent<{open: boolean, toggleDrawer: (open: boolean) => void}> = ({open, toggleDrawer}) => {
    const theme = useTheme();
    const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const scrollTo = (index: number) => {
        toggleDrawer(false);
        const element = document.getElementById(anchors[index]);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <SwipeableDrawer
            anchor={isSmallDevice ? 'right' : 'left'}
            open={open}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
        >
            <List>
                <ListItem sx={{height: 60}}/>
                {pages.map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton onClick={() => scrollTo(index)}>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </SwipeableDrawer>
    );
}

export default SwipeableTDrawer;
