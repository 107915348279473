import {createTheme, responsiveFontSizes } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#1b2b3c',
        },
        secondary: {
            main: '#e08d04',
        }
    },
    typography: {
        fontFamily: 'Lato',
        h1: {
            fontFamily: 'Trajan pro',
        },
        h2: {
            fontFamily: 'Trajan pro',
        },
        h3: {
            fontFamily: 'Trajan pro',
        },
        h4: {
            fontFamily: 'highest-praise',
        },
        h5: {
            fontFamily: 'highest-praise',
        },
        h6: {
            fontFamily: 'highest-praise',
        },
    }
});

export default responsiveFontSizes(theme);
