import main from "../images/clusette-main.png";
import {Box, Grid} from "@mui/material";
import {AnimatePresence, motion} from "framer-motion";
import logo from "../images/logo_clusius.png";
import React, {FunctionComponent} from "react";

const Overview: FunctionComponent<{ isSmallDevice: boolean }> = ({isSmallDevice}) =>
    <Grid container height={'100vh'} sx={{
        backgroundImage: `url(${main})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: 'left 50% bottom 100%'
    }}/>

export default Overview;
