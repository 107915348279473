import {Box, Grid, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import contactImg from '../images/contact.jpg';
import React, {FunctionComponent} from "react";

const Contact: FunctionComponent<{ isSmallDevice: boolean }> = ({ isSmallDevice}) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <Grid container p={4} sx={{background: theme.palette.primary.main}} justifyContent={'center'}>
                <Typography variant={'h4'} color={'common.white'} id={"contact"}>
                    Nous contacter
                </Typography>
            </Grid>
            <Grid container p={4} sx={{background: theme.palette.primary.main}} justifyContent={'space-around'}
                  alignItems={'center'}>
                <Grid item xs={12} md={6}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2783.214976525531!2d4.831680776969593!3d45.766881771080556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f4eafec33285ab%3A0xad83a82d310bd7b7!2s14%20Rue%20du%20Pr%C3%A9sident%20%C3%89douard%20Herriot%2C%2069001%20Lyon!5e0!3m2!1sen!2sfr!4v1698857660659!5m2!1sen!2sfr"
                        width={'100%'} height={450} style={{border: 0}} loading="lazy"
                        title={'nous-localiser'}
                        referrerPolicy="no-referrer-when-downgrade"/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img src={contactImg} width={isMd ? '100%' : '300px'} alt={'contact_us'}/>
                    <Grid container item py={1}>
                        <Grid item xs={12} sm={6}>
                            <Typography textAlign={isMd ? 'center' : 'left'} variant={'h4'} color={'common.white'}>
                                Accueil
                            </Typography>
                            <Typography textAlign={isMd ? 'center' : 'left'} variant={'subtitle1'} color={'common.white'}>
                                Mar - Sam 11h30 - 22h30
                            </Typography>
                            <Typography textAlign={isMd ? 'center' : 'left'} variant={'subtitle1'} color={'common.white'}>
                                Dim 11h - 15h
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography textAlign={isMd ? 'center' : 'left'} variant={'h4'} color={'common.white'}>
                                Cuisine
                            </Typography>
                            <Typography textAlign={isMd ? 'center' : 'left'} variant={'subtitle1'} color={'common.white'}>
                                Mar - Sam 12h - 21h
                            </Typography>
                            <Typography textAlign={isMd ? 'center' : 'left'} variant={'subtitle1'} color={'common.white'}>
                                Dim 11h30 - 14h30
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box>
                        <List>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <AlternateEmailIcon color={'secondary'}/>
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{color: 'common.white'}}
                                              primary="contact@clusius.fr"/>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <MyLocationIcon color={'secondary'}/>
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{color: 'common.white'}}
                                              primary="14 rue Président Edouard Herriot 69001 Lyon"/>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <LocalPhoneIcon color={'secondary'}/>
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{color: 'common.white'}}
                                              primary="+33 7 57 83 23 32"/>
                            </ListItem>
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default Contact;
